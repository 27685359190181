import styled from 'styled-components'

export const IconWrapper = styled.img`
height: ${({ height = '50px' }) => height};
width: ${({ width = '50px' }) => width};
position: relative;
object-fit: contain;
object-position: center;
transform: ${({ transform }) => transform};
transition: ${({ transition }) => transition};
`;