import React, { useState, useEffect, useRef } from 'react'

import Flexbox from '../Flexbox'
import TiledInput from '../TiledInput'

import API from '../../API'

import { toTitleCase } from '../../utils'

const GeographicAreaDetailed = ({ counties = [], setCounties, selectedStates = [], step }) => {
    console.log("selectedStates", selectedStates, counties)
    const validatorAddItem = (trimmedInput, _counties) => {

        if (!_counties.find(it => it.id === trimmedInput)) {
            //setCounties
            return true
        }
        return false
    }

    const validatorRemoveItem = (trimmedInput, _counties) => {
        return !trimmedInput.length && _counties.length
    }

    const formatSelection = (trimmedInput) => {
        // const _state = states.find(it => it.name.toLowerCase() === trimmedInput.toLowerCase())

        // if (_state)
        //     return _state.name
        // else 
        return trimmedInput
    }

    const isSelected = (name) => {
        console.log("counties", counties, name)

        let regex = new RegExp(name, "i")
        return counties.find(it => regex.test(it.id))
    }

    const getSuggestions = async (_input, setInput) => {
        //value: state.name, id: state.name
        const apicallResults = []
        let { cities: cityRecords, counties: countyRecords } = await API.searchPlacesAndCounties(_input, selectedStates)
        console.log("cityRecords:::: ", cityRecords)
        console.log("countyRecords:::: ", countyRecords)
        countyRecords = countyRecords.reduce((arr, item) => {
            const { fields: { coty_name, coty_name_long, geo_point_2d, ste_name, ste_code, coty_code } } = item

            if (!coty_name.includes(toTitleCase(_input.split(',')[0].trim())))
                return arr
            const formattedItem = {
                type: 'county', //county
                innerText: `${coty_name_long}, ${ste_name}`,
                value: `${coty_name_long}, ${ste_name}`,
                county: coty_name,
                name: coty_name,
                coty_code,
                state: ste_name,
                ste_name,
                ste_code,
                id: `${coty_name_long}, ${ste_name}`,
                geo_point_2d
            }
            if (isSelected(formattedItem.id))
                return arr



            arr.push({
                ...formattedItem,
                onClick: () => {
                    if (!isSelected(formattedItem.id)) {
                        // addState(state)
                        setCounties(formattedItem)
                        setInput('')
                    }
                },
                key: item.id
            })
            return arr
        }, [])

        if (countyRecords.length > 0)
            countyRecords.splice(0, 0, {
                innerText: 'Counties',
                value: 'Counties',
                onClick: () => { },
                id: 'Counties',
                props: {
                    hoverOff: true,
                    className: 'listHeader'
                },
                isKey: true
            })
        cityRecords = cityRecords.reduce(
            (arr, item) => {
                // console.log("item id: ", item, item['fields']['pla_code'], isSelected(item['fields']['pla_code']))

                const { fields: { coty_name, pla_name, pla_code, geo_point_2d, ste_name, ste_code, coty_code } } = item
                const formattedItem = {
                    type: 'place', //place
                    innerText: `${pla_name}, ${ste_name}`,
                    value: `${pla_name}, ${ste_name}`,
                    pla_code,
                    name: pla_name,
                    coty_code,
                    coty_name,
                    county: coty_name,
                    state: ste_name,
                    ste_name,
                    ste_code,
                    id: `${pla_name}, ${ste_name}`,
                    key: pla_code,
                    // id: `${pla_name}, ${ste_name}`,
                    geo_point_2d
                }
                if (isSelected(formattedItem.id))
                    return arr


                arr.push({
                    ...formattedItem,
                    onClick: () => {
                        if (!isSelected(formattedItem.id)) {
                            // addState(state)
                            setCounties(formattedItem)
                            setInput('')
                        }
                    },
                    key: item.id
                })
                return arr
            }
            , [])
        console.log("cities formatted: ", cityRecords)

        if (cityRecords.length > 0)
            cityRecords.splice(0, 0, {
                innerText: 'Cities',
                value: 'Cities',
                onClick: () => { },
                id: 'Cities',
                props: {
                    hoverOff: true,
                    className: 'listHeader'
                },
                isKey: true
            })

        return cityRecords.concat(countyRecords)

    }
    console.log("countiescountiescounties", counties)
    return (
        <Flexbox column alignItems="center" maxWidth="700px" width="100%" className="geographicArea">
            <h1 style={{ margin: '0', textAlign: 'center', fontFamily: 'Albert Sans' }}>Enter cities and/or counties below.</h1>
            <h5 style={{ margin: '', maxWidth: '500px', textAlign: 'center' }}>If you only want specific cities and/or counties within the state(s) you selected, enter them below. (Optional).</h5>
            <TiledInput
                // onFocus={onFocus}
                width="100%"
                maxWidth="700px"
                className="geographicArea"
                validatorAddItem={validatorAddItem}
                placeHolder={counties.length > 0 ? "County, State" : "San Francisco, California"}
                items={counties}
                setItems={setCounties}
                tagOnClick={function (_tag) { setCounties(_tag) }}
                // validatorRemoveItem={validatorRemoveItem}
                formatSelection={formatSelection}
                getSuggestions={getSuggestions} />
        </Flexbox>
    )
}

export default GeographicAreaDetailed