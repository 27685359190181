import React, { useContext, useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'

import Cookies from 'js-cookie';
import API from '../API'

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [loadingUser, setLoadingUser] = useState(true)
    const login = async (userNameOrEmail, password) => {
        try {
            setLoadingUser(true)
            const res = await API.login({
                email: userNameOrEmail,
                username: userNameOrEmail,
                password
            })
            const { token, user, expiresIn } = res

            // localStorage.setItem('token', JSON.stringify(token))
            // localStorage.setItem('expiresIn', JSON.stringify(dayjs().add(parseInt(expiresIn.replace(/\D/g, '')), expiresIn.replace(/\b\w/g, '')).unix()))
            
            // Store the JWT token in an HTTP-only cookie with a one-day expiration
            Cookies.set('jwt', token, { expires: 1, secure: true });


            setCurrentUser({
                token,
                user
            })
            setLoadingUser(false)
            return res
        } catch (error) {
            setCurrentUser(null)
            setLoadingUser(false)
            Cookies.remove('jwt')
            // localStorage.removeItem('token')
            // localStorage.removeItem('expiresIn')
            return error.response.data
        }
    }
    const logout = () => {
        setCurrentUser(null)
        Cookies.remove('jwt')
    }

    const value = {
        login,
        logout,
        currentUser,
        loadingUser
    }

    const checkIfAuthed = () => {
        if (
            Cookies.get('jwt')
        ) {
            setCurrentUser({token: Cookies.get('jwt'), user: {}})
        }
    }

    useEffect(() => {
        checkIfAuthed()
    }, [])
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}