import React, { useState, useEffect, useRef } from 'react'
import InputField from '../InputField'
import Tag from '../Tag'
import SearchTermHints from '../SearchTermHints'
import Flexbox from '../Flexbox'

import { InnerContainer, TagItem, TagsInputContainer, Input } from './TiledInput.styles'

const TiledInput = ({
    data,
    validatorAddItem,
    handleChange,
    validatorRemoveItem,
    placeHolder = 'Enter State(s)',
    maxTerms = -1,
    multiTermEnabled = true,
    onChange,
    formatSelection,
    getSuggestions,
    onFocus,
    onBlur,
    items,
    setItems,
    tagOnClick,
    maxWidth = "400px",
    className
}) => {
    const parentContainer = useRef(null)
    const listContainerRef = useRef(null)
    const inputRef = useRef(null)
    const dropdownRef = useRef(null)
    const parsingMenu = useRef(false)

    const [dropdownSelection, setDropdownSelection] = useState(null)
    const [suggestions, setSuggestions] = useState([])
    const [showDropdown, setShowDropdown] = useState(false)

    const resetSearchResults = () => {
        setSuggestions([])
        setShowDropdown(false)
        setDropdownSelection(-1)
    }

    const onBlurProps = {
        outline: 'none',
        boxShadow: 'inset 0 0 5px rgb(0, 0, 0)'
    }
    const onFocusProps = {
        outline: '1px solid white',
        boxShadow: 'inset 0 0 5px rgba(255, 255, 255, .5)'
    }
    const [focusProperties, setFocusProperties] = useState(onBlurProps)
    const { outline, boxShadow } = focusProperties


    const [isKeyReleased, setIsKeyReleased] = useState(false);
    const [input, setInput] = useState('')
    const _validatorAddItem = (trimmedInput, items) => {
        if (typeof (validatorAddItem) === 'function')
            return validatorAddItem(trimmedInput, items)
        return !items.find(el => el.id === trimmedInput) && trimmedInput.length
    }

    const _validatorRemoveItem = (trimmedInput, items) => {
        console.log("trimmedInput", trimmedInput, items)
        if (typeof (validatorRemoveItem) === 'function')
            return validatorRemoveItem(trimmedInput, items)
        return !items.find(el => el.id === trimmedInput) && !trimmedInput.length
    }

    const onKeyDown = (e) => {

        const { key } = e;
        const trimmedInput = input.trim();
        console.log("[onKeyDown] trimmedInput: ", trimmedInput)
        console.log("[onKeyDown] key: ", key)
        if ((key === 'ArrowDown' || key === 'ArrowUp')) {
            toggleThroughMenu(key === 'ArrowUp' ? -1 : 1)

        } else {
            parsingMenu.current = false
        }

        if ((
            // key === ',' || 
            key === 'Enter') && _validatorAddItem(trimmedInput, items)) {
            e.preventDefault();
            let _value = trimmedInput
            if (typeof (formatSelection) === 'function')
                _value = formatSelection(_value)
            const _newItems = [...items, { value: _value, id: _value }].slice(0, maxTerms > 0 ? maxTerms : [...items, trimmedInput].length)
            setItems(_newItems, _value);
            setInput('');
            // setSuggestions([])
        }

        if (key === "Backspace" && isKeyReleased && _validatorRemoveItem(trimmedInput, items)) {

            const itemsCopy = [...items];
            const poppedTag = itemsCopy.pop();
            console.log("[onKeyDown / Backspace] poppedTag: ", poppedTag)
            console.log("[onKeyDown / Backspace] trimmedInput: ", trimmedInput)
            console.log("[onKeyDown / Backspace] items: ", items)
            console.log("[onKeyDown / Backspace] itemsCopy: ", itemsCopy)
            // if (poppedTag) {
            e.preventDefault();
            setItems(itemsCopy.slice(0, maxTerms > 0 ? maxTerms : itemsCopy.length), poppedTag.id);
            // handleChange(poppedTag.id)
            setInput(poppedTag.id || '');
            // _validatorRemoveItem(poppedTag.id, items)
            setSuggestions([])
            // }
        }
        if (key === 'Escape'){
            setSuggestions([])
        }

        setIsKeyReleased(false);

    };


    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const toggleThroughMenu = (dir) => {
        if (suggestions.length > 0) {
            const _suggestionsNoKeys = suggestions.filter(it => !it.isKey)
            let val = _suggestionsNoKeys.findIndex(it => it.innerText === dropdownSelection) + dir

            val =
                val >= _suggestionsNoKeys.length ? 0 :
                    val < 0 ? _suggestionsNoKeys.length - 1 :
                        val
            console.log("_suggestionsNoKeys", _suggestionsNoKeys, val, dir)
            onToggleThroughMenu(_suggestionsNoKeys[val].innerText, val)
        }
    }


    const onToggleThroughMenu = (value, index) => {
        parsingMenu.current = true
        setInput(value)
        console.log("value", value)
        setDropdownSelection(value)
    }

    const handleLongTermsList = () => {
        if (parentContainer.current) {
            const { scrollWidth, offsetWidth } = parentContainer.current
            if (scrollWidth > offsetWidth)
                parentContainer.current.scrollLeft = parentContainer.current.scrollLeftMax

        }
    }



    const handleLongSuggestionsList = () => {
        if (listContainerRef.current) {
            const { scrollHeight, offsetHeight } = listContainerRef.current
            if (scrollHeight > offsetHeight)
                listContainerRef.current.scrollTop = listContainerRef.current.scrollTopMax

        }
    }


    useEffect(() => {
        if (typeof (onChange) === 'function') {
            onChange(items)

        }
        handleLongTermsList()
    }, [items])

    const shouldExecuteSearch = () => input.length > 0 && !parsingMenu.current

    const shouldResetSearchSuggestions = () => !parsingMenu.current || input.length === 0

    return (
        <Flexbox column relative width="100%" maxWidth={maxWidth} className={className}>
            <TagsInputContainer outline={outline} boxShadow={boxShadow} maxWidth={maxWidth} className={className}>
                <InnerContainer ref={parentContainer} >
                    {items.map((tag, index) => (
                        <Tag
                            margin="1px"
                            color={'#fff'}
                            key={tag.id || tag.value || tag}
                            onClick={function () {
                                if (typeof (tagOnClick) === 'function') {
                                    tagOnClick(tag)
                                } else {
                                    _validatorRemoveItem(tag.value  || tag, items)
                                    const _newItems = [...items.filter(it => it.value !== (tag.value  || tag))]
                                    setItems(_newItems, tag.value  || tag)
                                }

                            }}
                            name={tag.value || tag} />)
                    )}
                    {(multiTermEnabled || items.length === 0) &&
                        <InputField
                            refId={inputRef}
                            onBlur={() => {
                                setFocusProperties(onBlurProps)
                                // setInput('')
                                if (typeof (onBlur) === 'function') {
                                    const trimmedInput = input.trim();
                                    onBlur(trimmedInput)
                                    // setInput('')
                                }

                            }
                            }
                            background="transparent"
                            onFocus={() => {
                                {
                                    setFocusProperties(onFocusProps)
                                    if (typeof (onFocus) === 'function')
                                        onFocus()
                                }

                            }}
                            boxShadow="none"
                            focusOutline="none"
                            focusBorder="focusBorder"
                            outline="none"
                            border="none"
                            fontSize="16px"
                            boxShadowFocus="none"
                            minWidth="200px"
                            padding="0 5px"
                            type="text"
                            placeholder={placeHolder}
                            onKeyDown={onKeyDown}
                            onKeyUp={onKeyUp}
                            value={input}
                            onChange={(e) => setInput(e.target.value)} />}
                </InnerContainer>

            </TagsInputContainer>
            <SearchTermHints
                handleLongSuggestionsList={handleLongSuggestionsList}
                listContainerRef={listContainerRef}
                marginTop="calc(70px + 10px)"
                height="49px"
                minHeight="49px"
                dropdownRef={dropdownRef}
                trigger={input}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                currSelection={dropdownSelection}
                setDropdownSelection={setDropdownSelection}
                shouldExecuteSearch={shouldExecuteSearch}
                resetSearchResults={resetSearchResults}
                shouldResetSearchSuggestions={shouldResetSearchSuggestions}
                getAndFormatSearchResults={() => getSuggestions(input, setInput)}
            />
        </Flexbox>
    )
}

export default TiledInput