import React, { useEffect, useState } from 'react'

import axios from 'axios'

const Test = () => {
    const [stateSections, setStateSections] = useState({})
    const [section, setSection] = useState('1')
    const searchQuery = 'San+'
    const stateArr = ['California', 'Texas']
    useEffect(() => {
        const getCounties = async () => {
            //
            try {
                // const params = {
                //     action: 'parse',
                //     origin: '*',
                //     page: 'List_of_counties_by_U.S._state_and_territory',
                //     format: 'json'
                // }
                // if (section !== null) {
                //     params.section = section
                //     params.prop = 'text'
                // }
                // let { data: { parse: { sections, text } }
                //     //&prop=text
                //     //&section

                // } = await axios({
                //     method: 'get', // & controllers
                //     url: `https://en.wikipedia.org/w/api.php?`,
                //     params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined && it[1] !== null)) },
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                // });

                // console.log("text", text['*'])
                // if (sections) {
                //     const _sectionIdsByState = {}
                //     sections.forEach(({ line, index }) => {
                //         _sectionIdsByState[line] = index
                //     })
                //     setStateSections(_sectionIdsByState)
                // }

                // //CITIES:
                // const { data: { data: _data } } = await axios({
                //     method: 'get', // & controllers
                //     url: `https://wft-geo-db.p.rapidapi.com/v1/geo/cities`,
                //     params: { countryIds: 'Q30', namePrefix: 'California' },
                //     headers: {
                //         'X-RapidAPI-Key': '6bea409d7fmsh8440dbae8cda2e8p10ff5fjsn61842ff7e7ad',
                //         'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
                //     }
                // }); //get wikidataid

                //CITIES IN A STATE:
                // const countryID = 'Q30'
                // const regionCode = 'Q99' (state)
                // const options = {
                //     method: 'GET',
                //     url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries/${countrID}/regions/${regionCode}/cities`,
                //     params: { namePrefix: 'san', sort: '-population' },
                //     headers: {
                //         'X-RapidAPI-Key': '6bea409d7fmsh8440dbae8cda2e8p10ff5fjsn61842ff7e7ad',
                //         'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
                //     }
                // };

                // //CITIES:
                // const { data: { data } } = await axios({
                //     method: 'get', // & controllers
                //     url: `https://wft-geo-db.p.rapidapi.com/v1/geo/cities`,
                //     params: { countryIds: 'Q30', namePrefix: 'San' },
                //     headers: {
                //         'X-RapidAPI-Key': '6bea409d7fmsh8440dbae8cda2e8p10ff5fjsn61842ff7e7ad',
                //         'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
                //     }
                // });

                // console.log("res", data)

                const { data: { records } } = await axios({
                    method: 'get', // & controllers
                    url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-county`,
                    params: {
                        q: searchQuery,
                        sort: 'coty_name',
                        facet: 'ste_name',
                        'facet': 'coty_code',
                        ...Object.fromEntries(stateArr.map(state => ([['refine.ste_name'], state])))
                        // 'refine.ste_name': 'California',
                    },
                    headers: {
                        'Content-type': 'application/json',
                    }
                });

                console.log("res", records)
            } catch (error) {
                console.log(error)
            }
        }

        getCounties()



    }, [])
    console.log("state sections", stateSections)
    return (
        <div>Test</div>
    )
}

export default Test