import React, { useMemo } from 'react'
import Flexbox from '../../Flexbox'

const getHeight = (stepType) => {
    switch (stepType) {
        case 'textArea':
            return 'min-content'
        case 'input':
            return 'min-content'
        default:
            return '100%'
    }
}

const getStepTypeProps = (stepType) => {
    
    switch (stepType) {
        case 'textArea':
            return {}
        case 'geographicArea':
            return {
                overflowMobile: 'auto'
            }
        case 'input':
            return { maxWidth: '600px' }
        case 'iconSelection':
            return {
                justifyContent: 'center',
                justifyMobile: 'start'
            }
        case 'final-step':
            return {
                overflow: 'auto',
                justifyContent: 'start',
                // maxHeight: 'calc(100vh - 100px)'
            }
        default:
            return {}
    }
}

const getHeightAndStepTypeProps = (stepType) => {

    return ({ defaultStepTypeProps: getStepTypeProps(stepType), height: getHeight(stepType) })
}

const FormContentContainer = ({ children, stepType, ...props }) => {
    const { height, defaultStepTypeProps } = useMemo(() => {

        return ({ ...getHeightAndStepTypeProps(stepType) })
    }, [stepType]);
    return (
        <Flexbox
            justifyContent="center"
            column
            width="100%"
            height={height}
            position="relative"
            gridArea="content"
            className="content"
            gridRow="1 / span 1"
            flexBasis="400px"
            {...defaultStepTypeProps}
            // {...props}
            >{children}</Flexbox>
    )
}

export default FormContentContainer