import React from 'react'

import InputField from '../../InputField'
import NavArrow from '../NavArrow'
import Flexbox from '../../Flexbox'
import GeographicArea from '../../GeographicArea/indexNEW.js'
import GeographicAreaDetailed from '../../GeographicAreaDetailed'
import RadioButtons from '../../RadioButtons'
import Checkbox from '../../Checkbox'
import IconSelection from '../../IconSelection'
import TextStep from '../../TextStep'
import FinalStep from '../FinalStep'
import { GridWrapper } from './Step.styles'

const Step = ({ geographicAreaOnChange, geographicAreaDetailedOnChange, iconSelectionOnChange, backStep, nextStep, step, handleChange, onKeyDown, onKeyUp, stepOrder, steps, selectedStates }) => {

    const { name, value, placeholder, type } = step
    if (step.name === 'submit')
        return (
            <Flexbox column>
                <h1>Submitting...</h1>
            </Flexbox>
        )
    if (step.name === 'geographicArea')
        return <GeographicArea
            stateValues={value}
            handleChange={function (_val, skipNationwideSwitch = false) {
                // step.onChange(_val, stepOrder, steps, skipNationwideSwitch)
                geographicAreaOnChange(_val)
            }} ></GeographicArea>
    if (step.name === 'geographicAreaDetailed')
        return <GeographicAreaDetailed step={step} counties={value} selectedStates={selectedStates} setCounties={function (_val) { geographicAreaDetailedOnChange(_val) }}></GeographicAreaDetailed>
    if (step.name === 'finalStep') {
        return (<FinalStep {...step} backStep={backStep} step={step} handleChange={handleChange} onKeyUp={onKeyUp} onKeyDown={onKeyDown} nextStep={nextStep} />)
    }
    if (type && type === 'radio')
        return <RadioButtons options={step.enum} onClick={step.onChange} selected={step.value} textAreaOnChange={step.textAreaOnChange} listWidth="100%"></RadioButtons>
    if (type && type === 'iconSelection')
        return <IconSelection options={step.enum} label={step.getLabel} {...step}
            onChange={step['multiSelect'] ? (optinId, currValue, steps, option) => iconSelectionOnChange(step['id'], option) : (option) => iconSelectionOnChange(step['id'], option)}
        />
    if (type === 'text')
        return <TextStep {...step} />
    if (type && type === 'checkbox')
        return <Checkbox checked={step.phoneCallRequested} onCheck={() => step.onChange(step.checked)} text={step.checkboxText} />
    if (step.name === 'phoneNumber')
        return (
            <Flexbox gap="10px">
                <Flexbox flexGrow="2" flexBasis="100%">
                    <InputField
                        // onKeyPress={continueKey}
                        type={type ? type : name === 'email' ? "email" : 'text'}
                        onChange={handleChange(`${name}`)}
                        // onChange={function (_val) { step.onChange(_val, 'phoneNumber') }}
                        name={name}
                        placeholder={placeholder['phoneNumber']}
                        marginBottom={'20px'}
                        value={value['phoneNumber']}
                        width="100%"
                        onKeyDown={onKeyDown(`${name}`)}
                        onKeyUp={onKeyUp}
                        uppercaseOff={true}
                    />
                </Flexbox>
                <Flexbox fontSize="12px">
                    ext
                </Flexbox>
                <Flexbox flexShrink="2" flexBasis="30%">
                    <InputField
                        // onKeyPress={continueKey}
                        type={type ? type : name === 'email' ? "email" : 'text'}
                        // onChange={handleChange(`${name}`)}
                        onChange={function (_val) { step.onChange(_val, 'extension') }}
                        name={'extension'}
                        placeholder={placeholder['extension']}
                        marginBottom={'20px'}
                        value={value['extension']}
                        width="100%"
                        // onKeyDown={onKeyDown(`${name}`)}
                        onKeyUp={onKeyUp}
                        uppercaseOff={true}
                    />
                </Flexbox>
            </Flexbox>
        )
    return (
        <>{step['labelInternal'] &&
            < Flexbox
                // gridCol="2" gridRow="1" 
                gridArea="label"
                // alignSelf="end"
                {...step['labelStyling']}>
                <h3 style={{ margin: 'inherit' }}>
                    {
                        step['label']
                    }
                </h3>
            </Flexbox>
        }
            <InputField
                // onKeyPress={continueKey}
                type={type ? type : name === 'email' ? "email" : 'text'}
                onChange={handleChange(`${name}`)}
                name={name}
                placeholder={placeholder}
                marginBottom={'20px'}
                value={value}
                width="100%"
                onKeyDown={onKeyDown(`${name}`)}
                fontSize="16px"
                onKeyUp={onKeyUp}
                uppercaseOff={true}
                maxWidth="600px"
                border="none; border-bottom: 1px solid #fff"
                // fontSize="16px"
                labelTextAlign="left"
                labelFontSize="14px"
                focusOutline="none" padding="0 40px 0 20px"
                outline="none"
                focusBorder="none; border-bottom: 2px solid #fff"
                flexDirection="column"
                background="rgba(255,255,255,0.1)"
            />
            {
                step['subtext'] && step['subtext']
            }
        </>
    )
}

export default Step