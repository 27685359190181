import axios from "axios";
import Cookies from "js-cookie";

const api = {
    disputeLead: async (data) => {
        try {
            let res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_DOMAIN}/clients/disputeLead`,
                data
            });
            return { success: true, ...res['data'] }
        } catch (error) {
            if (error.response && error?.response.data)
                return { ...error?.response.data, success: false }
            else
                return { success: false, msg: "An Unknown error occurred" }
        }

    },
    getBudget: async (uId) => {
        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/users/getBudget?id=${uId}`
        });
        return res
    },
    checkout: async (data) => {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_DOMAIN}/users/checkout`,
            data: data
        });
        return res['data']

    },
    submitForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/form/submit/newAttorney`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    submitContactForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/contact`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    getFAQ: async () => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/faq/`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return res.data.faq
        } catch (error) {
            console.log(error)
        }

    },
    searchCounties: async (searchQuery = '', stateArr = []) => {
        try {
            let stateArrString = ''
            // api can only handle 2 states
            if (stateArr.length <= 2)
                stateArrString = stateArr.reduce((a, b, index) => {
                    let str = '&refine.ste_name[]=' + b.replace(' ', '+')
                    a = a + str
                    return a
                }, '')
            let _query = searchQuery.split(',')[0].replaceAll(' ', '+')
            if (searchQuery.split(',').length > 1) {
                let _q = searchQuery.split(',')[1].trim().replaceAll(' ', '+')
                if (_q.length > 0)
                    stateArrString = '&refine.ste_name=' + stateArr.find(el => el.includes(_q))
            }

            let { data: { records } } = await axios({
                method: 'get', // & controllers
                // url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-county&q=${searchQuery}&rows=200&sort=coty_name&facet=year&facet=ste_name`,
                url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-county&q=${_query}&rows=200&sort=coty_name&facet=year&facet=ste_name${stateArrString}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return records
        } catch (error) {
            console.log(error)
        }

    },
    searchPlaces: async (search = '', states = []) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/places/searchPlaces`,
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    search: search.replaceAll(' ', '+'),
                    states: states.map(st => st.replaceAll(' ', '+'))
                }
            });
            return res.data['result']
        } catch (error) {
            console.log(error)
        }
    },
    searchPlacesAndCounties: async (search = '', states = []) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/places/searchPlacesAndCounties`,
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    search: search.replaceAll(' ', '+'),
                    states: states.map(st => st.replaceAll(' ', '+'))
                }
            });
            return res.data
        } catch (error) {
            console.log(error)
        }
    },
    login: async (data) => {
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/clients/login`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: { ...data, username: data['email'] }
        });
        return res['data']
        // try {
        //     let res = await axios({
        //         method: 'post', // & controllers
        //         url: `${process.env.REACT_APP_API_DOMAIN}/clients/login`,
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         data: { ...data, username: data['email'] }
        //     });
        //     return res['data']
        // } catch (error) {
        //     console.log(error)
        //     if (error.response && error.response.data)
        //         return (error.response.data?.msg || 'An error occurred.')
        // }
    },
    getDisputeOptions: async () => {
        try {
    
            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/clients/getDisputeOptions`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return res.data
        } catch (error) {
            console.log(error)
        }
    },
    getDisputes: async (obj) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/clients/getDisputes`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Cookies.get('jwt')
                },
                params: obj
            });
            return res.data
        } catch (error) {
            console.log(error)
        }
    },
    forgotPassword: async (email) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/clients/forgotPassword`,
                headers: {
                    'Content-Type': 'application/json',
                },
                params: { email }
            });
            return res.data['message']
        } catch (error) {
            console.log(error)
            if (error.response && error.response.data)
                return (error.response.data?.msg || 'An error occurred.')
        }
    },
    resetPassword: async (data) => {
        try {

            let res = await axios({
                method: 'post', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/clients/resetPassword`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data
            });
            return { msg: res.data['msg'], success: true }
        } catch (error) {
            console.log(error)
            if (error.response && error.response.data)
                return ({ msg: error.response.data?.msg || 'An error occurred.', success: false })
        }
    },
    // searchPlaces: async (searchQuery = '', stateArr = []) => {
    //     try {
    //         let stateArrString = ''
    //         if (stateArr.length <= 2)
    //             stateArrString = Array.isArray(stateArr) ? stateArr.reduce((a, b, index) => {
    //                 let str = '&refine.ste_name[]=' + b.replace(' ', '+')
    //                 a = a + str
    //                 return a
    //             }, '') : '&refine.ste_name[]=' + stateArr.replace(' ', '+')

    //         let _query = searchQuery.split(',')[0].replaceAll(' ', '+')
    //         if (searchQuery.split(',').length > 1) {
    //             let _q = searchQuery.split(',')[1].trim().replaceAll(' ', '+')
    //             if (_q.length > 0)
    //                 stateArrString = '&refine.ste_name=' + stateArr.find(el => el.includes(_q))
    //         }


    //         let { data: { records } } = await axios({
    //             method: 'get', // & controllers
    //             url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=${_query}&rows=200&sort=coty_name&exclude.pla_type=CDP&facet=year&facet=ste_name&facet=ste_code&facet=coty_code&facet=coty_name&facet=cousub_code&facet=cousub_name&facet=pla_code&facet=pla_name&facet=pla_type${stateArrString}`,
    //             // url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=${searchQuery.replaceAll(' ','+')}&rows=200&sort=coty_name&exclude.pla_type=CDP&facet=year&facet=ste_name&facet=ste_code&facet=coty_code&facet=coty_name&facet=cousub_code&facet=cousub_name&facet=pla_code&facet=pla_name&facet=pla_type`,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         return records
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }
}

export default api;