import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Flexbox from '../Flexbox'
import Button from '../Button'


const Error = ({ }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [error, setError] = useState({
        msg: 'An error occurred.',
        status: 400,
        background: "#fff",
        color: "var(--primary)",
        buttonText: "Home",
        onClick: () => { navigate('/') }
    })
    const { buttonText, onClick } = error
    useEffect(() => {
        if (location['state'] && location['state']['error']) {
            const isForm = location['state']['ref'] === 'form-submission'
            setError(prev => ({
                ...prev,
                ...location['state']['error'],
                background: isForm ? "var(--formBackground)" : "var(--background)",
                color: isForm ? "#fff" : "#000",
            }))
}

    }, [location])
return (
    <Flexbox

        column
        justifyContent="center"
        color={error.color}
        background={error.background}
        height="calc(100vh - var(--navBarHeight))"
    >
        <h1>Error {error.status}</h1>
        <p>An error occurred:</p>
        <h3>{error.msg}</h3>
        <p style={{ textAlign: "center" }}>Please try again later.<br />If this problem persists, please <strong onClick={() => navigate('/contact')}>contact us</strong>.</p>
        <Button width="90%" maxWidth="300px" text={buttonText} border="2px solid #fff" onClick={onClick} />
    </Flexbox>
)
}

export default Error