import styled from 'styled-components'

import { Wrapper } from '../GridContainer/GridContainer.styles'


export const GridWrapper = styled(Wrapper)`
grid-template-areas: ${({ gridTempAreas = "'. label .''back content next'" }) => gridTempAreas};
overflow: hidden;
.formNavigation{
            align-self: center;
        }
/* display: grid;
gap: 20px;
width: ${({ width }) => width || '700px'};
padding: 0 50px;
grid-template-columns: min-content 1fr min-content;
grid-template-rows: repeat(2,min-content); */



@media only screen and (max-width: 550px){
            width: 100vw; //min-content minmax(300px,min-content) min-content
            grid-template-areas: ${({ gridTempAreasMobile = "'label label''content content''back next'" }) => gridTempAreasMobile};
            grid-template-columns: ${({ gridTempColsMobile = '1fr 1fr' }) => gridTempColsMobile};
            grid-template-rows:  ${({ gridTempRowsMobile = "min-content minmax(275px,100%) min-content" }) => gridTempRowsMobile};
            /* grid-template-rows: min-content minmax(275px,min-content) min-content; */
            /* grid-template-rows: min-content min-content 1fr; */ //calc(100% - 20px)
            height: ${({ mobileHeight = 'calc(100% - 25px - var(--navBarHeight))' }) => mobileHeight};
            top: var(--navBarHeight);
            margin: 0;
            /* align-content: center; */
            align-content: end;
            padding: ${({ mobilePadding = '20px' }) => mobilePadding};
            .formNavigation{
            align-self: end;
        }
        .content{
            align-self: start;
        }
        /* .iconSelectionTile {
            height: 85px;
            width: 85px;
            min-height: 85px;
            flex-basis: 85px;
            padding: 10px;
            gap: 5px;
        } */
        /* .iconSelectionTile img,  */
        .iconSmall img{
            height: 25px;
            width: 25px;
        }

        .iconMedium img {
            height: 40px;
            width: 40px;
        }
        }
        
`;