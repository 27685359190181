import React from 'react'
import Flexbox from '../Flexbox'



const TextStep = ({ heading, subtext, ...props }) => {
    return (
        <Flexbox alignText="center" column {...props}>
            <h3 style={{ textAlign: 'center' }}>{heading}</h3>
            {subtext &&
                <p>
                    {subtext}
                </p>
            }
        </Flexbox>
    )
}

export default TextStep