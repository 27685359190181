import React, { useEffect, useRef, useState } from 'react'

import { SearchIcon, AlertIconWrapper, Wrapper, TextAreaStyled, LabelStyled, InnerWrapper, DollarSign } from './TextArea.styles'

import CheckmarkValidate from '../CheckmarkValidate'

const Label = ({ label, name, marginBottom, labelTextAlign, ...props }) => (


    <LabelStyled
        htmlFor={name}
        labelTextAlign={labelTextAlign}
        {...props}
    ><h3 style={{ textAlign: labelTextAlign ? labelTextAlign : undefined }}>{label}</h3></LabelStyled>
)


const TextArea = ({
    onKeyUp,
    onKeyDown,
    onFocus,
    rows, maxLength,
    hideLabel = false,
    showValidationCheckmark,
    showCharCount = false,
    disabled,
    activeSearch, clearAll, clearListOnSearch, onSearch,
    isSearch = false, focusOnMount, marginTop, textAlign, uppercaseOff,
    type, image, onChange = () => { }, placeholder, value, label, name, onKeyPress, priceOnBlur, ...props }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);
    function handleCursor(event) {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);
        }
    }, [value])

    useEffect(() => {
        if (focusOnMount && inputRef.current !== null)
            inputRef.current.focus()
    }, [name])

    if (label !== undefined && !hideLabel) {

        return (
            <Wrapper {...props}>
                <Label htmlFor={name}
                    label={label}
                    {...props}
                ></Label>
                <InnerWrapper  {...props}>
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <TextAreaStyled
                        isSearch={isSearch}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onKeyUp={onKeyUp}
                        rows={rows !== undefined ? rows : 3}
                        maxLength={maxLength !== undefined ? maxLength : 1000}
                        {...props}
                    />
                    {showValidationCheckmark && <CheckmarkValidate {...props}/>}
                </InnerWrapper>
                {
                    (showCharCount && maxLength) && <div style={{ fontSize: '10px' }}>{value.length}/{maxLength}</div>
                }
            </Wrapper >

        )
    }
    else {
        return (
            <>
            <InnerWrapper {...props}>
                {name === 'price' && <DollarSign>$</DollarSign>}
                <TextAreaStyled
                    isSearch={isSearch}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled ? true : false}
                    onChange={handleCursor}
                    name={name}
                    onKeyPress={onKeyPress}
                    ref={inputRef}
                    onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    rows={rows !== undefined ? rows : 3}
                    maxLength={maxLength !== undefined ? maxLength : 1000}
                    {...props}
                />
                {showValidationCheckmark && <CheckmarkValidate {...props}/>}
                
            </InnerWrapper>
            {
                    (showCharCount && maxLength) && <div style={{ fontSize: '10px', alignSelf: 'start' }}>{value.length}/{maxLength}</div>
                }
            </>
            
        )
    }



}

export default TextArea
