import React from 'react'
import styled from 'styled-components'
import Flexbox from '../Flexbox'
import FooterDisclaimer from '../FooterDisclaimer'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Button from '../Button'
import logo from '../../images/eagleWhite.svg'
const Link = styled(NavLink)`
color: #fff;
width: min-content;
white-space: nowrap;
grid-area: ${({ gridArea }) => gridArea};
grid-column: ${({ col }) => col};
grid-row: ${({ row }) => row};
:hover{
    text-decoration: underline;
}
`;

const OnPageLink = styled.a`
color: #fff;
width: min-content;
white-space: nowrap;
grid-area: ${({ gridArea }) => gridArea};
grid-column: ${({ col }) => col};
grid-row: ${({ row }) => row};
:hover{
    text-decoration: underline;
}
`;

const MailLink = styled.a`
color: inherit;
width: min-content;
:hover{
    text-decoration: underline;
}
`;

const List = styled.ul`
    margin: 0;
    margin-left: 50px;
    padding: 0;
    li{
        margin-top: 20px;
    }
    `

const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    
    `

const Footer = ({ background = "var(--formBlue1)", color = "#fff" }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const linksStyling = {
        row: '2'
    }
    if (pathname.includes('get-started') || pathname.includes('form'))
        return (<></>)
    return (
        <Flexbox column  background="var(--formBackground)" boxShadow="rgba(0, 0, 0, 0.5) 0px 0 5px" zIndex="3" position="relative">
            <GridContainer
                mobileBreakPoint="1080px"
                position="relative"
                justify="center"
                align="center"
                gridTemp={{ cols: '1fr', rows: 'min-content min-content' }}
                gridTempMobile={{ cols: '1fr', rows: 'min-content min-content min-content' }}
                column maxWidth="100vw" width="100%" overflow="hidden" padding="75px 30px 0" gap="30px" color="#fff">
                <img src={logo} height="70%" width="auto" style={{
                    // borderRadius: '50%',
                    opacity: '0.05',
                    position: 'absolute',
                    alignSelf: 'start',
                    left: '50px',
                    top: '50%',
                    transform: "translateY(-50%)",
                    maxWidth: 'calc(100vw - 100px)',
                    // maxHeight: 'auto',
                    objectFit: 'contain'
                }} />
                <Flexbox maxWidth="1200px" className="wrapMobile" flexGrow="2" position="relative" justifyContent="start" alignItems="center" gap="25px 0">
                    <GridContainer alignSelf="center" justifySelf="center" gap="10px" column
                        alignItems="center" flexBasis="50%"
                        justifyItemsMobile="center"
                        gridTemp={{ rows: 'repeat(3, min-content)' }}
                        flexGrowMobile="1" alignText="start" mobileMargin="0" paddingMobile="0"
                        padding="0 50px 0 0" className="noPaddingMobile">
                        <h2 style={{ margin: '0', fontWeight: '700', textAlign: 'center', width: 'min-content', whiteSpace: 'nowrap' }}>Lawyer Pay Per Lead</h2>
                        <h4 style={{ fontSize: '1.1rem', textAlign: 'left' }} className="centerOnMobile">Real-time exclusive leads from targeted search ads</h4>
                        <Button
                            width="100%"
                            // maxWidth="300px"
                            mobileMax="100%"
                            border="none" borderHover="none" colorHover="#fff" text="Get Started"
                            boxShadow="rgba(0, 0, 0, 0.5) 0px 0px 7px"
                            onClick={() => navigate('/get-started')}
                            background="rgba(255,255,255,0)"
                            bgdHover="rgba(255,255,255,0.15)" />
                    </GridContainer>
                    <Flexbox border="none;border-left: 1px solid #fff"
                        // padding="0 0 0 25px"

                        height="100%" row alignItems="start" alignSelf="center" justifyContent="start" alignText="start" className="colOnMobile">
                        <Flexbox

                            position="relative"
                            column

                            justifyContent="start"
                            // padding="0 0 0 50px"
                            alignItems="start"
                            // gap="20px"
                            alignText="left"
                        >
                            <List>
                                <h4>Resources</h4>
                                <ListItem><Link to="/privacy-policy">Privacy Policy</Link></ListItem>
                                <ListItem><Link to="/do-not-sell-my-info">Do Not Sell My Info</Link></ListItem>
                                <ListItem><Link to="/terms">Terms & Conditions</Link></ListItem>
                                <ListItem><Link to="/refund-policy">Refund Policy</Link></ListItem>
                                <ListItem>{
                                    pathname.includes('privacy-policy') &&
                                    <OnPageLink href="#cookies-and-tracking">Cookie Policy</OnPageLink>
                                }
                                    {
                                        !pathname.includes('privacy-policy') &&
                                        <Link to="/privacy-policy#cookies-and-tracking">Cookie Policy</Link>
                                    }
                                </ListItem>
                            </List>
                        </Flexbox>
                        <Flexbox
                            position="relative"
                            column
                            justifyContent="start"
                            alignItems="start"
                            mobileMargin="25px 0 0"
                            alignText="left"
                        >
                            <List>
                                <h4>Menu</h4>
                                <ListItem><Link to="/">Home</Link></ListItem>
                                <ListItem><Link to="/about">About</Link></ListItem>
                                {/* <ListItem>
                                    {
                                        pathname !== '/' &&
                                        <>
                                            <Link to="/#how-it-works">How It Works</Link>

                                        </>
                                    }
                                    {
                                        pathname === '/' &&
                                        <>
                                            <OnPageLink href="#how-it-works">How It Works</OnPageLink>
                                        </>
                                    }
                                </ListItem> */}
                                <ListItem><Link to="/faq">FAQ</Link></ListItem>
                                {/* <ListItem><Link to="/pricing">Pricing</Link></ListItem> */}
                                <ListItem><Link to="/contact">Contact Us</Link></ListItem>
                                {/* <ListItem><Link to="/login">Login</Link></ListItem> */}
                            </List>
                        </Flexbox>
                        <Flexbox
                            position="relative"
                            column
                            justifyContent="start"
                            alignItems="start"
                            mobileMargin="25px 0 0"
                            alignText="left"
                        >
                            <List>
                                <h4 >Get In Touch</h4>
                                <ListItem><MailLink
                                    href="mailto:help@lawyerpayperlead.com" >help@lawyerpayperlead.com</MailLink></ListItem>
                                
                                <ListItem><Flexbox >
                                    
                                </Flexbox></ListItem>
                            </List>
                        </Flexbox>
                    </Flexbox>

                </Flexbox>

                <GridItem color="#fff" mobileRow="3" fontSize="12px" padding="0 50px" position="relative" alignText="center">

                    Copyright ©2024 <span className='cpName'></span>. All Rights Reserved.
                </GridItem>
            </GridContainer>
            
            <FooterDisclaimer background={background} color={color}/>
        </Flexbox>
    )
}

export default Footer