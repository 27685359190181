import styled from "styled-components";

export const GridContainer = styled.div`
/* box-shadow: ${({ boxShadow }) => boxShadow || '0 0 2px #000'}; */
background: ${({ background }) => background};
height: var(--navBarHeight);
padding: 0 50px;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 20px;
grid-template-rows: 1fr;
width: 100%;
position: relative;
z-index: 2;
.hamburgerMenu{
    display: none;
}
.desktopMenu{
    display: flex;
}

@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    .hamburgerMenu{
        display: flex;
    }
    .desktopMenu{
        /* visibility: hidden; */
        display: none;
        
    }
    .hamburgerMenu svg rect{
        transition: all 0.25s ease-in-out;
    }

        }
`;