import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import styled from 'styled-components'
import Flexbox from './Flexbox'
import CheckmarkValidate from './CheckmarkValidate'
import { Label } from './InputField'

const PhoneInputStyled = styled(PhoneInput)`
/* border: 10px solid blue; */
.PhoneInputInput{
    height: 49px;
        color: ${({ color = 'inherit' }) => color};
        caret-color: ${({ color = "inherit" }) => color};
        font-size: ${({ fontSize = '16px' }) => fontSize};
        width: 100%;
        margin: 2px;
        background: ${({ background = "var(--underLineThemeBgd)"}) => background };
        outline: none;
        border: ${({ border = "1px solid #fff"}) => border };
        box-shadow: var(--defaultInputBoxShadow);
        /* border-bottom: 1px solid #fff; */
        border-radius: 0;
        padding: ${({ padding =  '0 40px 0 20px'}) => padding};
        &:focus{
            /* border: none; */
            /* border-bottom: var(--underlineFocus); */
            box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8);
            outline: ${({ focusOutline = "none"}) => focusOutline };
            border: ${({ focusBorder }) => focusBorder || 'var(--inputFocusBorder)'};
            background: ${({focusBgd}) => focusBgd};
            color: ${({focusColor}) => focusColor};
        }
        ::placeholder{
            color: ${({ inputPlaceHolderColor = 'var(--inputPlaceHolderColor)' }) => inputPlaceHolderColor};
        }
}
`;

const PhoneInput2 = ({
    showValidationCheckmark, value,
    onCountryChange = () => { },
    setValue = () => { },
    onChange,
    showLabel = false,
    placeholder = "Enter Phone Number",
    labelProps,
    ...props
}) => {
    return (
        <> {showLabel && <Label {...props} {...labelProps} />}
            <Flexbox width="100%" position="relative">

                <PhoneInputStyled
                    international={false}
                    // withCountryCallingCode
                    addInternationalOption={false}
                    countryCallingCodeEditable={false}
                    country="US"
                    defaultCountry="US"
                    countrySelectProps={{ unicodeFlags: true }}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange ? onChange : setValue}
                    {...props}
                // inputComponent={InputField}
                // onCountryChange={onCountryChange}
                ></PhoneInputStyled>
                {showValidationCheckmark && <CheckmarkValidate {...props} />}
            </Flexbox>
        </>
    )
}

export default PhoneInput2