const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2, xNb = null, yNb = null) => {
    let elements;
    elements = document.querySelectorAll(className);
    Array.from(elements).forEach((element) => {
        let BBox = element.getBBox();
        let BBoxX2 = xFactor == 0 ? 0 : xNb ? xNb : BBox["width"] / xFactor;
        let BBoxY2 = yFactor == 0 ? 0 : yNb ? yNb : BBox["height"] / yFactor;
        let BBoxX = xNb ? xNb : BBox["x"] + BBoxX2;
        let BBoxY = yNb ? yNb : BBox["y"] + BBoxY2;
        BBox = BBoxX + "px" + " " + BBoxY + "px";
        element.style.transformOrigin = BBox;
    });
}

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const formatPhoneNumber = (phoneNumber) => {
    let number = phoneNumber.slice(0,10).split('').reduce((a, b, index) => {
        if ((index ) % 3 === 0 && index < 7 && index > 0)
            a = a + "-" + b
        else
            a = a + b
        return a
    }, '')
    return number
}

export const formatPrice = (_value) => {
    const MAX_DEC_PLACES = 2
    let value = _value.toString()
    let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let decimal; //MAX_DEC_PLACES
    if (value.split('.').length > 1) {
        decimal = (value.split('.')[1] + '0').substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
        price = price + '.' + decimal;
    }
    return '$' + price
}

export class GridTemp {
    constructor(r, c) {
        this.rows = r
        this.columns = c
    }
}

class IconSelectionOption {
    /**
     * 
     * @param {string} displayName 
     * @param {*} img 
     * @param {string=} id 
     * @param {string=} text 
     * @param {Object} imgProps 
     * @param {*} subcategories 
     */
    constructor(displayName, img = null, id = null, text = null, imgProps, subcategories) {
        this.id = id || displayName.replace(/ /g, "-").toLowerCase()
        this.img = img
        this.text = text || displayName
        this.subcategories = subcategories
        this.imgProps = imgProps
    }
}

const debounce = (callback, wait) => {
    console.log("debouncing")
    let timeout;
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => { 
            callback.apply(this, args) }, wait)
    }

}

function gtag_report_conversion(url = undefined, send_to) {
    var callback = function () {
        if (typeof (url) !== undefined)
            window.location = url

    }
    let conversionOb = { 'send_to': send_to }
    console.log("send to:", send_to)
    console.log("url: ", typeof(url))
        conversionOb['event_callback'] = callback
        console.log("conversionOb", conversionOb)
    window.gtag('event', 'conversion', {
        ...conversionOb
    })
    return false

}

export { debounce, fixSvgElementCoordinates, toTitleCase, formatPhoneNumber, IconSelectionOption, gtag_report_conversion }