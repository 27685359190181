import React from "react";
import { useLocation } from "react-router-dom";

export const useQuery = (param = '') => {
    const { search } = useLocation()

    return React.useMemo(() => {
        let params = new URLSearchParams(search)
        if (param.length)
            return params.get(param)
        else return params
    })
}
