import React from 'react'
import styled from 'styled-components';
import Flexbox from '../Flexbox'
import { NavLink } from 'react-router-dom'

const Link = styled(NavLink)`
all: unset;
cursor: pointer;
font-weight: 600;
color: ${({ color = '#fff' }) => color};
width: min-content;
white-space: nowrap;
`;
const FooterDisclaimer = ({ background = "var(--formBlue1)", color = "rgba(255,255,255,0.5)" }) => {

    return (
        <Flexbox padding="0 30px 0" alignText="center" color={color}>
            <p  style={{fontSize: '12px', opacity: '0.5'}}>ATTORNEY ADVERTISING: lawyerpayperlead.com is not a law firm or an attorney referral service.  The information provided on this site, or any affiliated postings such as videos, blogs, social media, or elsewhere, is not legal advice.  No attorney-client or confidential relationship is, or will be, formed by usage of the site.  This site is a pooled attorney advertisement.  Participating Attorneys and/or law firms who appear on our site, or Participating Attorneys or law firms who contact Requestors based on form submissions, have paid an advertising fee.  Your use of this website constitutes acceptance of our <Link to="/terms" color={color}>Terms & Conditions</Link> and <Link to="/privacy-policy" color={color}>Privacy Policy</Link>.</p>
        </Flexbox>
    )
}

export default FooterDisclaimer