import { set } from "animejs";
import React, { useState, useRef, useEffect } from "react";

export const useIntersectionObserver = (options, runOnce = true) => {
    const containerRef = useRef(null)
    const didRun = useRef(false)
    const [isVisible, setIsVisible] = useState(false)
    const callbackFunction = (entries) => {
        // console.log("observing")
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
        if (entry.isIntersecting)
            didRun.current = true
    }
    const observer = new IntersectionObserver(callbackFunction, options)

    useEffect(() => {

        if (containerRef.current)
            observer.observe(containerRef.current)

        const unobserve = () => {
            if (containerRef.current) {
                
                observer.unobserve(containerRef.current)
                // setIsVisible(true)
            }
        }

        return () => {
            unobserve()
        }
    }, [containerRef, options])

    useEffect(() => {
        if (runOnce && containerRef.current && didRun.current) {
            // console.log("unobserving")
            observer.unobserve(containerRef.current)
            setIsVisible(true)
        }
    }, [isVisible, didRun])

    return [containerRef, isVisible]
}