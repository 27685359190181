import styled from 'styled-components'

export const CheckboxStyle = styled.label`
margin-bottom: 2px;
display: flex;
gap: 2px;
align-items: center;
justify-content: start;
font-size: ${({ fontSize = "var(--inputLabelFontSize)" }) => fontSize};
color: ${({ color = 'var(--checkboxColor)' }) => color};
/* font-weight: 200; */
letter-spacing: 0.02rem;
white-space: nowrap;
position: relative;
align-self: start;
/* width: min-content; */
min-height: ${({ minHeight = "50px" }) => minHeight};
margin: ${({ margin }) => margin};

/* width: 30px; */
span{
    /* margin-left: 5px; */
    display: inline-block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background: var(--inputBackground);
    /* border: var(--defaultCheckRadioBorder); */
    position: relative;
    box-shadow: var(--defaultInputBoxShadow);
    cursor: pointer;
    box-shadow: inset 0px 0px 2px rgba(255, 255, 255, 0.35);
    border: ${({ borderUnchecked = "1px solid rgba(255,255,255,0.3)" }) => borderUnchecked};
    border-radius: 3px;
}

strong{
    margin-left: 0.2rem;
    color: (--primary);
    font-weight: 700;
}

input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

input:checked ~ span{

    /* background: var(--formBlue1);
    box-shadow: var(--inputBoxShadow);
    border: var(--checkboxBorder); */
    
    border: ${({ borderChecked = "1px solid rgba(255,255,255,0.3)" }) => borderChecked};
    background: var(--neonBlue);
    background: #fff;
}

input:checked ~ span:after{
    content: "";
    position: relative;
    display: block;
    top: 1px;
    width: 5px;
    height: 11px;
    margin: auto auto;
    border: 1px solid var(--formBlue1);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
label{
    margin-left: 0.2em;
}

.checkbox-text{
      white-space: normal;
      /* width: 100%; */
      text-align: start;
    }
`;