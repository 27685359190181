import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import PageContentContainer from '../PageContentContainer'
import Flexbox from '../Flexbox'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Button from '../Button'
import API from '../../API'
const PaymentSuccessful = () => {
    const checkoutSessionId = useQuery('session_id')
    console.log("checkout session id", checkoutSessionId)
    return (
        <PageContentContainer background="var(--formBackground)" justifyContent="center" color="#fff" column>
            <Flexbox column maxWidth="750px">
                <h1>Thanks for your payment!</h1>
                <h2>You'll receive an email when your payment has been processed with your updated budget.</h2>
                <h2>Note that if you have received leads between checkout and the completion of your processed payment, the budget reflected in your email will differ from that you saw on the checkout page.</h2>
            </Flexbox>
        </PageContentContainer>
    )
}

export default PaymentSuccessful