import React from 'react'
import Checkbox from '../Checkbox'
import Flexbox from '../Flexbox'
import styled from 'styled-components'
import { Label } from '../InputField'

const GridContainer = styled.div`
display: grid;
width: 100%;
grid-template-columns: repeat( auto-fit, minmax(min-content, 50%) );
justify-content: start;
grid-auto-flow: row;
grid-auto-rows: min-content;
`;
const MultiSelect = ({ label, name, labelTextAlign = "left", options, groupedOptions, onSelect, value = [], ...props }) => {
    return (
        <>
            <Label htmlFor={name}
                label={label}
                labelTextAlign={labelTextAlign}
                {...props} />
            {
                options &&
                <Flexbox column maxHeight="350px" flexWrap="wrap" mobileMaxHeight="unset">
                    {options.map((it, index) => {

                        let obSpread = typeof (it) === 'object' ? it : {}
                        return (<Checkbox {...obSpread} key={index + 1} text={it} minHeight="unset"
                            fontSize="16px"
                            margin="5px 20px 5px 0"
                            {...Object.fromEntries(Object.entries(props).filter(it => it[0] !== 'onChange'))}
                            onCheck={(e) => {
                                // console.log(e.target.value)
                                onSelect(it)
                            }} checked={value.includes(it)} />)
                    })}</Flexbox>
            }
            {
                groupedOptions &&
                Object.keys(groupedOptions).map(key => {
                    const { options: _options, label } = groupedOptions[key]
                    let obSpread = typeof (it) === 'object' ? it : {}
                    return (
                        <>
                            <h4>{label}</h4>
                            <GridContainer >
                                {_options.map((it, index) =>
                                    <Checkbox {...obSpread} key={index + 1} text={it} minHeight="unset"
                                        fontSize="16px"
                                        margin="5px 20px 5px 0"
                                        {...Object.fromEntries(Object.entries(props).filter(it => it[0] !== 'onChange'))}
                                        onCheck={(e) => {
                                            // console.log(e.target.value)
                                            onSelect(it)
                                        }} checked={value.includes(it)} />)}
                            </GridContainer>
                        </>)
                })
            }

        </>
    )
}

export default MultiSelect