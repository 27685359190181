import React, { useState, useEffect, useRef } from 'react'
import Flexbox from '../Flexbox'
import { useNavigate, useLocation } from 'react-router-dom'
import Dropdown from '../Dropdown'
import { GridContainer } from './Navigation.styles'
import { fixSvgElementCoordinates } from '../../utils'

import eagle from '../../images/eagle.svg'
import eagle2 from '../../images/eagle2.svg'
import eagle3 from '../../images/eagle3.svg'
import text from '../../images/text.svg'

import eagleWhite from '../../images/eagleWhite.svg'
import { useAuth } from '../../contexts/AuthContext'
import { Button as PrimeButton } from 'primereact/button'
import styled from 'styled-components'

export const Button = styled(PrimeButton)`
background: #fff;
border-color: var(--formBlue2);
width: ${({ width }) => width};
&.p-button{
    padding: ${({ padding }) => padding};
    margin: ${({ margin }) => margin};
}
.p-button-label{
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ color = "var(--formBlue2)" }) => color};
}
&.p-button:hover{
    
    background: ${({ bgdH = "var(--formBlue2)" }) => bgdH};
    border-color: ${({ borderC = "var(--formBlue2)" }) => borderC};
    .p-button-label{
        color: ${({ colorH = '#fff', text }) => {
        if (text)
            return undefined
        return '#fff'
    }};
    }
}
`;
const Navigation = () => {
    const gridContainerRef = useRef(null)
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const [showDropdown, setShowDropdown] = useState(false)

    useEffect(() => {
        if (gridContainerRef.current) {
            fixSvgElementCoordinates('.hamburgerMenu svg .upperLine', 0, 2, 5, 4)
            fixSvgElementCoordinates('.hamburgerMenu svg .lowerLine', 0, 67, 5, 67)
        }

    }, [gridContainerRef.current])
    // const links = ['FAQ', 'Contact', 'About', 'Pricing']
    const links = ['FAQ', 'Contact', 'About']
    const fillColor = (defaultColor = '#000') => location.pathname !== '/about' && !location.pathname.includes('privacy') && !location.pathname.includes('terms') && !location.pathname.includes('refund')
        && !location.pathname.includes('do-not-sell-my-info') ? "#fff" : defaultColor
    const colorDropdown = location.pathname !== '/about' ? "#fff" : undefined
    const bgdDropdown = location.pathname !== '/about' ? "var(--formBackgroundAngledTransparent)" : undefined

    const getMenuOptions = () => {
        let _options = [
            { value: 'Home', innerText: 'Home', onClick: () => navigate('/') },
            ...links.map(it => {
                const path = it.replace(' ', '-').toLowerCase()
                return ({ value: it, innerText: it, onClick: () => { navigate(path) } })
            })]
        // if (currentUser)
        //     _options.push({ value: 'Log Out', innerText: 'Log Out', onClick: () => logout() })
        return (_options)
    }

    if (location.pathname.includes('get-started') || location.pathname.includes('form'))
        return (
            <GridContainer height="var(--navBarHeight)" padding="var(--navBarPadding)" zIndex="5" relative boxShadow="none" background="transparent">
                <Flexbox color="#fff" width="50px" justifyContent="center" margin="0 0 0 -20px" onClick={() => navigate('/')} fontWeight={location.pathname === '/' ? 'var(--navBarFontWeightActive)' : undefined}>&#10006;&#xFE0E;</Flexbox>

            </GridContainer>
        )
    return (
        <GridContainer ref={gridContainerRef} height="var(--navBarHeight)" padding="var(--navBarPadding)"
            // boxShadow={location.pathname === '/contact' ? "none" : "var(--navBarBoxShadow)"} 
            zIndex="5" relative>
            <Flexbox fontFamily="'Playfair Display', serif" onClick={() => navigate('/')}
                width="min-content"
                color={fillColor("#386aff")}
                // fontSize="21px"
                whiteSpace={"nowrap"}
                fontWeight={'var(--navBarFontWeightActive)'}>
                <img src={location.pathname !== '/about' && !location.pathname.includes('privacy') && !location.pathname.includes('terms')
                    && !location.pathname.includes('do-not-sell-my-info') && !location.pathname.includes('refund') ? eagleWhite : eagle} height="45px" width="50px"
                    style={{ objectFit: 'contain' }} />
                Lawyer Pay Per Lead
            </Flexbox>
            <Flexbox justifyContent="space-between" gap="20px" className="desktopMenu" color={location.pathname === '/contact' ? "#fff !important" : undefined}>
                {
                    // !currentUser &&
                    links.map(it => {
                        const path = it.replace(' ', '-').toLowerCase()
                        return (<Flexbox
                            key={path}
                            cursor="pointer"
                            whiteSpace="nowrap"
                            color={fillColor("#386aff")}
                            fontWeight={location.pathname.includes(path) ? 'var(--navBarFontWeightActive)' : undefined}
                            width="min-content"
                            onClick={() => {
                                navigate(path)
                            }}
                        >{it}</Flexbox>)
                    })
                }
            </Flexbox>
            {/* {currentUser && <Flexbox className={"desktopMenu"} justifyContent="end">
                <Button onClick={logout} label="Log Out" />
            </Flexbox>} */}
            <Flexbox column alignItems="end" className="hamburgerMenu" justifyContent="center" alignContent="end" justifyItems="end">

                <svg viewBox="0 0 100 80" width="20px" height="20px" onClick={() => {
                    let bodyEl = document.querySelector('body')
                    if (!showDropdown) {
                        bodyEl.style.overflow = 'hidden'
                    } else {
                        bodyEl.style.overflow = 'visible'
                    }
                    setShowDropdown(!showDropdown)
                }
                }>
                    <rect y="30" width="90" x="5" height="10"
                        style={{ fill: showDropdown ? "none" : fillColor() }}
                    ></rect>
                    <rect className="upperLine" width="90" height="10" x="5" style={{ transform: showDropdown ? "rotate(45deg)" : 'none', fill: fillColor() }}></rect>
                    {/* <rect y="30" width="90" height="10" x="5"  ></rect> */}
                    {/* style={{transform: "rotate(45deg)"}} */}

                    <rect className="lowerLine" y="60" width="90" x="5" height="10" style={{ transform: showDropdown ? "rotate(-45deg)" : 'none', fill: fillColor() }}></rect>
                </svg>
                {showDropdown &&
                    <Flexbox position="fixed" height="calc(100% - var(--navBarHeight))" width="100%" right="0" top="var(--navBarHeight)" zIndex="5">
                        <Dropdown
                            // dropdownRef={dropdownRef}
                            // listContainerRef={listContainerRef}
                            label='hamburger'
                            headerHeight="49px"
                            fontSize="1.3rem"
                            width="100%"
                            itemHeight="min-content"
                            itemPadding="20px 50px"
                            // height="calc(100vh - var(--navBarHeight))"
                            height="100%"
                            justifyContentList="end"
                            maxHeight={'100%'}
                            listBackground={bgdDropdown}
                            color={colorDropdown}
                            showHeader={false}
                            name={'Suggested Tags'}
                            marginBottom={'1rem'}
                            options={getMenuOptions()}
                            menuState={showDropdown}
                            handleStateChange={() => {
                                setShowDropdown(!showDropdown)
                                let bodyEl = document.querySelector('body')
                                bodyEl.style.overflow = 'visible'
                            }}
                            // currSelection={currSelection ? currSelection : null}
                            valueX={[]}
                            id={'suggested-tags'}
                            padding="0 0 var(--navBarHeight)"
                            setValidContinue={() => { }}
                            justifyContent="end"
                            textAlign="left"
                        // letterSpacing="0.1rem"
                        // fontSize="var(--fontSmall)"
                        // fontWeight="700"
                        // boxShadow='0 0 2px #000'
                        // background="#000"
                        ></Dropdown>
                    </Flexbox>}

            </Flexbox>

        </GridContainer >
    )
}

export default Navigation