import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'

import Checkout from './components/Checkout'
import About from './components/About'
import Pricing from './components/Pricing'
import Success from './components/Success'
import Test from './components/Test'
import Form from './components/Form'
import Error from './components/Error'
import Submitting from './components/Submitting'
import FAQ from './components/FAQ'
import Budget from './components/Budget'
import Navigation from './components/Navigation'
import ContactForm from './components/ContactForm'
import WhyOurLeadsCostMore from './components/WhyOurLeadsCostMore'
import PrivacyPolicy from './components/PrivacyPolicy';
import DNSMI from './components/DNSMI';
import TermsAndConditions from './components/TermsAndConditions';
import ScrollToTop from "./components/scrollToTop";
import PaymentSuccessful from './components/PaymentSuccessful'
// CSS
import { GlobalStyle } from './GlobalStyle';
import Footer from './components/Footer'
import { HelmetProvider } from 'react-helmet-async';
import DynamicMetaData from './components/DynamicMetaData'
import Dispute from './components/Dispute';
import DisputeStatus from './components/DisputeStatus';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import { AuthProvider } from './contexts/AuthContext';
import ResetPassword from './components/ResetPassword';
import RefundPolicy from './components/RefundPolicy';


function App() {
  return (

    <HelmetProvider>
      <AuthProvider>
        <div className="App">
          <Router>
            <DynamicMetaData />
            <ScrollToTop />
            <Navigation />
            <Routes>
              <Route path="/" exact element={< Home />} />
              <Route path="/dispute-status" element={< PrivateRoute />} >
                <Route path="/dispute-status" exact element={< DisputeStatus />} />
              </Route>
              <Route path="/reset-password" exact element={< ResetPassword />} />
              <Route path="/login" exact element={< Login />} />
              <Route path="/dispute-lead" exact element={< Dispute />} />

              <Route path="/submitting" exact element={< Submitting />} />
              <Route path="/test" exact element={< Test />} />
              {/* <Route path="/why-us" exact element={< WhyOurLeadsCostMore />} /> */}
              <Route path="/about" exact element={< About />} />
              <Route path="/faq" exact element={<FAQ />} />
              <Route path="/pricing" exact element={<Pricing />} />
              <Route path="/form" exact element={<Form />} />
              <Route path="/get-started" exact element={<Form />} />
              <Route path="/error" exact element={<Error />} />
              <Route path="/success" exact element={<Success />} />
              <Route path="/contact" exact element={<ContactForm />} />
              <Route path="/privacy-policy" exact element={< PrivacyPolicy />} />
              <Route path="/refund-policy" exact element={< RefundPolicy />} />
              <Route path="/checkout/:userId" exact element={< Checkout />} />
              <Route path="/do-not-sell-my-info" exact element={< DNSMI />} />
              <Route path="/terms" exact element={< TermsAndConditions />} />
              <Route path="/budget/:id" element={< Budget />} />
              <Route path="/payment-successful" exact element={< PaymentSuccessful />} />
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
            <Footer />
            <GlobalStyle />
          </Router>
        </div>
      </AuthProvider>
    </HelmetProvider>

  );
}

export default App;
