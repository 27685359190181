import React, { useEffect, useState } from 'react'
import InputField from './InputField'
import PageContentContainer from './PageContentContainer'
import TextArea from './TextArea'
import RadioButtons from './RadioButtons2'
import Flexbox from './Flexbox'
// import Button from './Button'
import MultiSelect from './MultiSelect'
import { Checkbox } from 'primereact/checkbox';
import API from '../API'

import { Button } from './Navigation';
import CheckmarkValidate from './CheckmarkValidate'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components'

const validatePassword = (pw) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
    return re.test(String(pw));
}

export const SubText = styled.div`
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
margin: ${props => props.margin ? props.margin : ''};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
user-select: none;
color: ${props => props.color ? props.color : 'var(--purpleGrey)'};
`;

const ResetPassword = () => {
    const navigate = useNavigate()
    const location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams();
    const { login, loadingUser, currentUser } = useAuth()


    const [state, setState] = useState({
        serverResponse: '',
        loading: false,
        success: false,
        isValid: false,
        shouldShow: false,
        sections: {
            'section-1': {
                fields: ["field-1", "field-2", "field-3"]
            }
        },
        fields: {
            "field-1": {
                id: "field-1",
                name: "email",
                type: "input",
                width: '100%',
                value: "",
                placeholder: "Email",
                label: "Email",
                isRequired: true,
                validate: function (v = this.value) {
                    return v.trim().length > 0
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-2": {
                id: "field-2",
                name: "newPassword",
                type: "password",
                width: '100%',
                value: "",
                placeholder: "New Password",
                label: "New Password",
                isRequired: true,
                validate: function (v = this.value) {
                    return validatePassword(v)
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },
            "field-3": {
                id: "field-2",
                name: "passwordV",
                type: "password",
                width: '100%',
                value: "",
                placeholder: "Confirm Password",
                label: "Confirm Password",
                isRequired: true,
                validate: function (v = this.value) {
                    return validatePassword(v)
                },
                get isValid() {
                    return this.validate(this.value)
                },
            },


        }
    })
    const { shouldShow, isValid, loading, success, fields, serverResponse } = state
    useEffect(() => {
        if (searchParams?.get('token')) {
            if (searchParams?.get('email'))
                onChange(fields[getFieldFromName('email')].id, searchParams?.get('email'))
        }
    }, [searchParams])
    const getFieldFromName = (name) => {
        return Object.keys(fields).find(field => {
            return fields[field].name.toLowerCase() === name.toLowerCase()
        })
    }
    const updateObj = (field, v) => {
        if (field.type === 'radio')
            return { value: v, selected: v }
        else {
            let returnObj = { value: v }
            if (typeof (field['validate']) === 'function') {
                returnObj['isValid'] = field['validate'](v)
            }
            return returnObj
        }

    }

    const onChange = (fieldId, e, updateKey = null) => {
        let v;
        if (typeof (e) === 'string')
            v = e
        else
            v = e?.target?.value || ''
        setState(prev => ({
            ...prev,
            fields: {
                ...prev.fields,
                [fieldId]: {
                    ...prev.fields[fieldId],
                    ...updateObj(fields[fieldId], v, updateKey)
                }
            }
        }))
    }
    const multiSelect = (fieldId, v) => {
        setState(prev => {
            let value = [...prev['fields'][fieldId].value, v]
                .reduce((a, b) => {
                    if (a.includes(b))
                        a = a.filter(it => it !== b)
                    else
                        a.push(b)
                    return a
                }, [])
            return ({
                ...prev,
                fields: {
                    ...prev.fields,
                    [fieldId]: {
                        ...prev.fields[fieldId],
                        value,
                        selected: v
                    }
                }
            })
        })
    }
    const validateFields = (fields = state.fields) => {

        return Object.keys(fields).reduce((a, fieldKey) => {
            if (!fields[fieldKey].isRequired || a === false || typeof (fields[fieldKey].validate) !== 'function')
                return a
            else
                return fields[fieldKey].validate()

        }, true)

    }
    return (
        <PageContentContainer margin="calc(var(--navBarHeight) * -1) 0 0" column background="var(--formBackground)" padding="50px 0 0" maxHeight="100%" height="100vh">

            <Flexbox background="#fff" padding="30px" border="1px solid #fff" column alignItems="start" width="100%" maxWidth="600px" margin="auto">
                <h1 style={{ color: 'var(--formBlue1)' }}>Welcome Back.</h1>
                {
                    Object.keys(fields).map(fieldKey => {
                        const field = fields[fieldKey]
                        let _shouldShow = false
                        if (field['isValid'] !== undefined) {
                            _shouldShow = !field['isValid'] && shouldShow
                        }
                        field['onChange'] = (e) => {
                            onChange(fieldKey, e)
                        }
                        if (field.type === 'input' || field.type === 'password')
                            return (
                                <>
                                    <InputField {...field} showValidationCheckmark={_shouldShow}
                                        focusBgd="#94cdff42"
                                        focusBorder='none;border-bottom:1px solid #000'
                                        inputPlaceHolderColor="#9d9b9b"
                                        focusColor="#000"
                                        labelFontSize=".8rem"
                                        background="#fff"
                                        color="var(--formBlue1)"
                                        stroke="var(--formBlue1)"
                                        border='none;border-bottom:1px solid var(--formBlue1)'
                                    />
                                    {
                                        field.name === 'passwordV' && <><SubText fontSize="12px" color={/^[a-zA-Z\d\w\W]{8,}.*$/.test(field.value) ? 'var(--green)' : 'var(--red)'}>Min 8 characters</SubText>
                                            <SubText fontSize="12px" color={/^(?=.*[A-Z]).*$/.test(field.value) ? 'var(--green)' : 'var(--red)'}>At least one uppercase letter</SubText>
                                            <SubText fontSize="12px" color={/^(?=.*[a-z]).*$/.test(field.value) ? 'var(--green)' : 'var(--red)'}>At least one lowercase letter</SubText>
                                            <SubText fontSize="12px" color={/\d/.test(field.value) ? 'var(--green)' : 'var(--red)'}>At least one number</SubText>
                                            <SubText fontSize="12px" color={field.value !== '' && field.value === fields[getFieldFromName('newPassword')].value ? 'var(--green)' : 'var(--red)'}>Passwords must match</SubText></>
                                    }
                                </>
                            )

                    })
                }
                <Button
                    // borderC="#fff"
                    // bgdH="rgba(255,255,255,0.1)"
                    onClick={async () => {
                        console.log("validating fields", validateFields())
                        let res = {};
                        try {
                            if (!validateFields()) {
                                setState(prev => ({
                                    ...prev,
                                    shouldShow: true,
                                    serverResponse: <span style={{ alignItems: 'center', display: 'flex', position: 'relative' }}>Please complete fields marked with a <CheckmarkValidate
                                        position="relative"
                                        top="unset"
                                        stroke="var(--formBlue1)"
                                        right="-3px"
                                        shouldShow={true} isValid={false} /></span>
                                }))
                            } else {
                                if (!loading && !success) {
                                    setState(prev => ({
                                        ...prev,
                                        loading: true,
                                        shouldShow: false,
                                        serverResponse: ""
                                    }))
                                    let res = await API.resetPassword({
                                        email: fields[getFieldFromName('email')].value,
                                        newPassword: fields[getFieldFromName('newPassword')].value,
                                        token: searchParams?.get('token'),
                                    })
                                    setState(prev => ({
                                        ...prev,
                                        loading: false,
                                        success: res['success'],
                                        serverResponse: res.msg
                                    }))
                                }
                                
                            }


                        } catch (error) {
                            console.log("error::")
                            console.log(error)

                            setState(prev => ({
                                ...prev,
                                loading: false,
                                success: false, //res['success'],
                                serverResponse: typeof (error) === 'string' ? error :
                                    '' //res['msg']
                            }))
                        }
                        console.log("res:", res)
                    }}
                    loading={loading}
                    width="100%"
                    padding="1em 0"
                    label="Submit" margin="20px 0 0" />
                <p style={{ color: 'var(--formBlue1)' }}>{serverResponse}</p>
            </Flexbox>
        </PageContentContainer>
    )
}

export default ResetPassword