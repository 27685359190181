import React, { useEffect, useState } from 'react'

import Flexbox from '../../Flexbox'

const ProgressBar = ({ active, getActiveIndex, formLength }) => {
    const [step, setStep] = useState(1)
    useEffect(() => {
        setStep(getActiveIndex() + 1)
    }, [active])
    return (
        <Flexbox
            absolute
            top="0" height="5px" width="100%"
            background="rgba(255,255,255,0.25)">
            <Flexbox transition="width 0.4s ease-in-out" relative height="5px" width={`${step / formLength * 100}%`} background="rgba(255,255,255,0.9)"></Flexbox>
        </Flexbox>
    )
}

export default ProgressBar